import MDinput from '@/components/MDinput';
import Select from '@/components/DSE/Select';
import { validatorRequireNumber } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    transactionType: {
      type: Select,
      defaultValue: null,
      props: {
        caption: 'loyaltyPoints.type',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('transactionType')
      },
      dynamicRules: form => [{ validator: validatorRequireNumber }]
    },
    transactionPoints: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'loyaltyPoints.points',
        type: 'number',
        precision: 0,
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
