<template>
  <div>
    <embedded-crud-table
      :entity="entity"
      :entity-store="entityStore"
      :columns="columns"
      :generate-summary="generateSummary"
      :definition="definition"
      :caption="caption"
      :disabled="disabled"
      :parent-id-filter-field="parentIdFilterField"
      :open-in-fullscreen="openInFullscreen"
      :required="required"
      :create-item-from-form-data="createItemFromFormData"
      :create-form-data-from-item="createFormDataFromItem"
      :permission-code="'LoyaltyPointsTransaction'"
      :parent-i-d="parentID"
      :show-summary="true"
      :summary-method="getSummaries"
    />
  </div>
</template>

<script>
import EmbeddedCrudTable from '@/components/crud/EmbeddedCrudTable';
import formMixin from './form-mixin';
import { TransactionType } from '@/utils/constants';
import { toDateTimeStringVisual } from '@/utils/date';

export default {
  components: {
    EmbeddedCrudTable
  },
  mixins: [formMixin],
  props: {
    caption: String,
    parentIdFilterField: {
      type: String,
      required: true
    },
    permissionCode: String,
    disabled: {
      default: false,
      type: Boolean
    },
    openInFullscreen: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    parentID: Number,
    totalPoints: String
  },
  data() {
    return {
      columns: [
        {
          field: 'createdAt',
          label: 'common.date',
          formatter: this.formatDate,
          filter: { type: 'date' }
        },
        {
          field: 'transactionType',
          label: 'loyaltyPoints.type',
          minWidth: '100px',
          sortable: true,
          formatter: this.formatTransactionTypeOption,
          filter: {
            type: 'select',
            options: this.$store.getters['option/loadedOptions']('transactionType'),
            valueField: 'code',
            labelField: 'description'
          }
        },
        {
          field: 'transactionPoints',
          label: 'loyaltyPoints.points'
        }
      ]
    };
  },
  methods: {
    getSummaries(param) {
      const sums = [];
      sums[0] = this.$i18n.t('common.total');
      const plus = param.data
        .filter(i => i.transactionType === TransactionType.Add)
        .map(i => i.transactionPoints)
        .reduce((a, b) => a + b, 0);
      const min = param.data
        .filter(i => i.transactionType === TransactionType.Substract)
        .map(i => i.transactionPoints)
        .reduce((a, b) => a + b, 0);
      sums[2] = plus - min;
      return sums;
    },
    generateSummary(row) {
      return this.$i18n.t('common.emptyString');
    },
    formatTransactionTypeOption(row, column, cellValue) {
      const option =
        cellValue !== null &&
        this.$store.getters['option/loadedOptions']('transactionType').find(item => item.code === cellValue);
      return option && option.description;
    },
    formatDate(row, column, cellValue) {
      return toDateTimeStringVisual(cellValue);
    }
  }
};
</script>

<style lang="scss" scoped></style>
