import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import Select from '@/components/DSE/Select';
import Switch from '@/components/DSE/Switch';

export function getFormDefinition(vue) {
  return {
    isMainAddressYN: {
      type: Switch,
      defaultValue: true,
      props: {
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    },
    streetName: {
      type: MDinput,
      props: {
        maxlength: 64,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    houseNumber: {
      type: MDinput,
      props: {
        maxlength: 64,
        caption: 'common.streetNumber',
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    postalCode: {
      type: MDinput,
      props: {
        maxlength: 8,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    city: {
      type: MDinput,
      props: {
        maxlength: 64,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    country: {
      type: Select,
      defaultValue: null,
      props: {
        valueField: 'code',
        labelField: 'name',
        loading: vue.$store.getters['country/isLoading'],
        options: vue.$store.getters['country/loadedItems']
      }
    },
    areaName: {
      type: MDinput,
      props: {
        maxlength: 64
      }
    },
    companyName: {
      type: MDinput,
      props: {
        maxlength: 64
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
