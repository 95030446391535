import MDinput from '@/components/MDinput';
import { validatorEmail, validatorRequire } from '@/utils/validators';
import Switch from '@/components/DSE/Switch';
import DateTimePicker from '@/components/DSE/DateTimePicker';
import CrudLoyaltyPoints from '../loyaltypointstransactions/CrudLoyaltyPointsTransactions';
import CrudAddresses from './addresses/CrudAddresses';
import TextArea from '../../../components/DSE/TextArea';

export function getFormDefinition(vue) {
  return {
    type: 'tab',
    saveButtons: [true, false, false], // Page, tab 1, tab 2, ...
    showFormLabels: [true, false, false], // Tab 1, tab 2, ....
    labels: ['common.customer', 'common.addresses', 'common.loyaltyPoints'],
    headerTemplate: (str, form) => `
    <h3>
    ${form.firstName + ' ' + form.lastName || ''}
    </h3>
    `,
    groups: [
      {
        salutation: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 16
          }
        },
        firstName: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        lastName: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        company: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64
          }
        },
        phone: {
          type: MDinput,
          defaultValue: '',
          props: {
            type: 'tel',
            phoneNumberMinLength: 10,
            phoneNumberMaxLength: 12
          }
        },
        email: {
          type: MDinput,
          defaultValue: '',
          props: {
            required: true,
            type: 'email',
            phoneNumberMinLength: 10,
            phoneNumberMaxLength: 12
          },
          rules: [{ validator: validatorEmail }]
        },
        dateOfBirth: {
          type: DateTimePicker,
          props: {
            caption: 'common.dateOfBirth',
            type: 'date',
            format: vue.$store.getters.dateFormat
          }
        },
        newsletterYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'customer.newsletter',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        hasAccountYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'customer.account',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        blackListYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'customer.blackList',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        excludeFromDirectMailYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'customer.excludeFromDirectMailYN',
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        },
        remarks: {
          type: TextArea,
          defaultValue: '',
          props: {
            maxlength: 2048
          }
        }
      },
      {
        addresses: {
          type: CrudAddresses,
          defaultValue: [],
          props: {
            caption: 'common.emptyString',
            openInFullscreen: false,
            parentIdFilterField: 'customerID'
          }
        }
      },
      {
        loyaltyPoints: {
          type: CrudLoyaltyPoints,
          defaultValue: [],
          props: {
            caption: 'common.emptyString',
            openInFullscreen: false,
            parentIdFilterField: 'customerID'
          }
        }
      }
    ]
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
const layoutFullWidth = {
  sections: [
    {
      width: 100
    }
  ]
};

export function getCreateLayoutDefinition(vue) {
  return {
    groups: [layoutFullWidth]
  };
}

export function getEditLayoutDefinition(vue) {
  return {
    groups: [layoutFullWidth, layoutFullWidth, layoutFullWidth]
  };
}
