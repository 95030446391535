import { render, staticRenderFns } from "./CrudAddresses.vue?vue&type=template&id=39bdca84&scoped=true&"
import script from "./CrudAddresses.vue?vue&type=script&lang=js&"
export * from "./CrudAddresses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39bdca84",
  null
  
)

export default component.exports