<template>
  <div>
    <embedded-crud-table
      :entity="entity"
      :entity-store="entityStore"
      :columns="columns"
      :generate-summary="generateSummary"
      :definition="definition"
      :caption="caption"
      :disabled="disabled"
      :allow-create="false"
      :parent-id-filter-field="parentIdFilterField"
      :open-in-fullscreen="openInFullscreen"
      :required="required"
      :create-item-from-form-data="createItemFromFormData"
      :create-form-data-from-item="createFormDataFromItem"
      :permission-code="'Customer'"
      :parent-i-d="parentID"
    />
  </div>
</template>

<script>
import EmbeddedCrudTable from '@/components/crud/EmbeddedCrudTable';
import formMixin from './form-mixin';
import { booleanColumnFilter, booleanColumnFormatter } from '../../../../utils/filters';

export default {
  components: {
    EmbeddedCrudTable
  },
  mixins: [formMixin],
  props: {
    caption: String,
    parentIdFilterField: {
      type: String,
      required: true
    },
    permissionCode: String,
    disabled: {
      default: false,
      type: Boolean
    },
    openInFullscreen: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    parentID: Number
  },
  data() {
    return {
      columns: [
        {
          field: 'streetName'
        },
        {
          field: 'houseNumber',
          label: 'common.streetNumber'
        },
        {
          field: 'postalCode'
        },
        {
          field: 'city'
        },
        {
          field: 'isMainAddressYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        {
          field: 'areaName'
        },
        {
          field: 'companyName'
        }
      ]
    };
  },
  methods: {
    generateSummary({ streetName, number, postalCode, city }) {
      return `${streetName} ${number} ${postalCode} ${city}`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
